import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  connect() {
    super.connect();

    this.fetch_link_preview();
  }

  fetch_link_preview(){
    let load_url = this.element.dataset.url;
    let target_element_id = this.element.dataset?.targetElementId != undefined ? this.element.dataset.targetElementId : this.element
    let target_element = $(target_element_id)

    $.ajax({
      url: load_url,
      method: "GET",
      success: function(data){
        if(data == ''){
          $(target_element).text('No Preview Available')
        }else{
          $(target_element).html(data)
        }
      },
      error: function(data) { $(target_element).text('No Preview Available')}
    })
  }
}