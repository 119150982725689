import ApplicationController from './application_controller'
export default class extends ApplicationController {

  search(event){
    event.target.dataset.isExportActive = $("#export-selected-button").is(':visible')
    event.target.dataset.selectedDocuments = $("#selected-deliverables-to-export").val()
    this.stimulate("DeliverablesReflex#search")
  }

  paginate(event){
    event.target.dataset.isExportActive = $("#export-selected-button").is(':visible')
    event.target.dataset.selectedDocuments = $("#selected-deliverables-to-export").val()
    this.stimulate("Deliverables#paginate")
  }

  select_document(event){
    let source_id = this.element.getAttribute("source_id")
    $("#deliverable_source_id").val(source_id)
    $("#offcanvasRightDocument .selected-document").html(this.element.innerText)
    $("#document-reference-list-section").html("")
  }

  select_new_document(event){
    if(this.element.dataset.documentAttached == 'true'){
      let allow_user = confirm(I18n.t('frontend.confirm_change_document'))

      if(!allow_user){
        event.preventDefault()
      }
    }
  }

  change_source_type(event){
    let data = { issue_id: this.element.dataset.issueId, 
                 source_type: this.element.value,
                 deliverable_id: this.element.dataset.deliverableId || "", info_note: this.element.dataset.infoNote }
    $.ajax({
      url: `/issues/${this.element.dataset.issueId}/deliverables/change_source_type`,
      method: "GET",
      data: data,
      success: function(data){ $("#deliverable-form-fields").html(data) },
      error: function(data) { console.error("Error")}
    })
  }

  open_document_selector(event){
    $(this.element).parents('.blank_deliverable_section').find('form.edit_deliverable #deliverable_document_file').trigger('click')
  }

  upload_document(event){
    this.element.form.submit()
  }

  show_selection_checkboxes(event){
    $("#Deliverables .deliverables-selection_checkboxes").removeClass('invisible')
    $("#Deliverables .deliverables-actions").addClass('invisible')
    $(".export-selection-active").removeClass('invisible')
    $(".infoNoteDoc").removeClass('invisible')
    $(".export-selection-inactive").parents('.three-dots-export').addClass('invisible')
    $("#exportDocumet").modal('show')
  }

  update_export_selection(event){
    let selected_values = JSON.parse($("#selected-deliverables-to-export").val())
    let submitURL = $("#export-selected-link").attr('href').split('?')[0]
    $(document).on('change', '#checkeInfoNoteCommentDocument', function(){
      if($(this).prop('checked')){
        $("#infoNoteComments").modal('show');
      }
    })
    let selected_comments_value = JSON.parse($("#selected-comments-to-export").val()) ;
    $('#selected-comments-to-export').on('click', function(){
      $("input:checkbox[name=selected-comments]:checked").each(function () {
        if ($(this).prop('checked')) {
          selected_comments_value.selected_comments.push($(this).val())
        } else {
          selected_comments_value.selected_comments.pop($(this).val())
        } 
      })
      $("#selected-comments-to-export").val(JSON.stringify(selected_comments_value))
    })    
    if($(this.element).is(':checked') ){
      selected_values.selected_deliverables.push($(this.element).attr('deliverable_id'))
    }else{
      selected_values.selected_deliverables.pop($(this.element).attr('deliverable_id'))
    }
    $("#selected-deliverables-to-export").val(JSON.stringify(selected_values))
    $("#selected-comments-to-export").val(JSON.stringify(selected_comments_value))
    $('#selected-comments-to-export').on('click', function(){
      $("#export-selected-link").attr('href', submitURL + `?selected_comments=${JSON.stringify(selected_comments_value)}&selected_deliverables=${JSON.stringify(selected_values)}`)
    });
    $("#export-selected-link").attr('href', submitURL + `?selected_comments=${JSON.stringify(selected_comments_value)}&selected_deliverables=${JSON.stringify(selected_values)}`)
  }

  export_selected_documents(event){
    $("#export-selected-link")[0].click()
    let submitURL = $("#export-selected-link").attr('href').split('?')[0]
    $("#Deliverables .deliverables-selection_checkboxes").addClass('invisible')
    $("#Deliverables .deliverables-selection_checkboxes input").prop('checked', false)
    $("#Deliverables .deliverables-actions").removeClass('invisible')
    $(".export-selection-active").addClass('invisible')
    $(".infoNoteDoc").addClass('invisible')
    $(".export-selection-inactive").parents('.three-dots-export').removeClass('invisible')
    $("#selected-deliverables-to-export").val(JSON.stringify({ "selected_deliverables": [] }))
    $("#selected-comments-to-export").val(JSON.stringify({ "selected_comments": [] }))
    $("#export-selected-link").attr('href', submitURL+ '?selected_comments={ \"selected_comments\": []}&selected_deliverables={ \"selected_deliverables\": []}')
  }

  remove_document_file(event){
    let docType = event.target.dataset.docType
    let uploadedFileSection = $(this.element).parents('.uploaded-file-section')
    let removeUploadedFileField = uploadedFileSection.find('#remove_uploaded_file')
    let docRefField = $("#deliverable_source_id")

    if(docType == 'Document'){
      removeUploadedFileField.val('true');
    }else if(docType == 'DocRef'){
      docRefField.val('')
    }
    uploadedFileSection.addClass('invisible')
  }
}
